import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Announcement = () => {
  const [userEmail, setUserEmail] = useState("");
  const [formData, setFormData] = useState({ announcement: "" });
  useEffect(() => {
    const getEmail = localStorage.getItem("email");
    setUserEmail(getEmail);
    fetchData(getEmail);
  }, []);

  const fetchData = async (email) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/get-announcement`
      );
      const announcement = res?.data?.data?.announcement || "";
      setFormData({ announcement });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (formData?.announcement?.length <= 200) {
        formData.userEmail = userEmail;
        console.log(formData);

        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/add-announcement`,
          formData
        );
        console.log("formData", formData, res);
        if (res?.status === 200) {
          toast.success("Published Successfully!", {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else
        toast.info("Announcement phrase limit exceeded!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error("Please Try Again!", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.log(error);
      toast.error("Internal Server Error!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div>
      <h3 style={{ color: "#0a3d89" }}>Announcement</h3>
      <br />
      <form className="" onSubmit={handleSubmit}>
        <div className="">
          <div className="mb-3">
            <textarea
              name="announcement"
              className="form-control"
              value={formData.announcement}
              onChange={handleChange}
              placeholder="Type Announcements"
              rows={5}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="btn w-full btn-primary "
              style={{ background: "#0a3d89", width: "100%" }}
            >
              Publish
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Announcement;
