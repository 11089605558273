import React, { useState } from "react";
import ShowSymbolData from "../ShowSymbolData/showSymbolData";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const Dashboard = () => {
  const [linesLimit, setLinesLimit] = useState(0);
  const navigate = useNavigate();

  const handleRoute = () => {
    if (linesLimit < 10) {
      // set limit of 10 lines
      navigate("/addsymboldetails");
    } else {
      toast.error("Lines limit reached !", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div>
      <div className="addLinesButton">
        <button
          onClick={handleRoute}
          type="button"
          class="btn btn-primary"
          style={{ background: "#0a3d89" }}
        >
          Add Lines
        </button>
      </div>
      <div>
        <ShowSymbolData setLinesLimit={setLinesLimit} />
      </div>
      <ToastContainer />
    </div>
  );
};
