import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/login`,
        formData
      );
      console.log("formData", formData, res);
      if (res?.status === 200) {
        localStorage.setItem("isUserValid", true);
        localStorage.setItem("email", formData.email);
        toast.success("Login Successfully !", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1500);
      } else {
        toast.error("Please Try Again !", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error("Invalid User !", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else
        toast.error("Internal Server Error !", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
  };
  return (
    <div className="containers loginPage">
      <form onSubmit={handleSubmit}>
        <div>
          <h2
            style={{
              color: "#0a3d89",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Forex Tech
          </h2>
        </div>
        <div className="mb-3">
          <label for="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            name="email"
            className="form-control"
            id="exampleInputEmail1"
            value={formData.email}
            onChange={handleChange}
            aria-describedby="emailHelp"
            placeholder="Enter Email"
          />
        </div>
        <div className="mb-3">
          <label for="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            name="password"
            className="form-control"
            value={formData.password}
            onChange={handleChange}
            id="exampleInputPassword1"
            placeholder="Enter Password"
          />
        </div>
        <button
          type="submit"
          className="btn w-full btn-primary"
          style={{ background: "#0a3d89" }}
        >
          Login
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;
