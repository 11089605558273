import React, { useEffect } from "react";
import "./sidebar.css";
import { useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location.pathname);

  // useEffect(() => {
  //   // Hide the sidebar initially on small screens
  //   if (window.innerWidth <= 900) {
  //     document.getElementsByClassName("sidebar")[0].classList.add("collapsed");
  //   }
  // }, []);
  return (
    <div>
      <div className="containers">
        <div className="content">
          <button
            onClick={() =>
              document
                .getElementsByClassName("sidebar")[0]
                .classList.toggle("collapsed")
            }
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
        </div>
        <div className="sidebar" id="sidebar">
          <div id="head">
            <p className="logo">Forex Lines</p>
          </div>
          <ul className="lists">
            <li
              style={{
                backgroundColor: `${
                  location.pathname === "/dashboard" ? "#003575" : "revert"
                }`,
              }}
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <span>Dashboard</span>
            </li>
            <li
             style={{
              backgroundColor: `${
                location.pathname === "/announcement" ? "#003575" : "revert"
              }`,
            }}
              onClick={() => {
                navigate("/announcement");
              }}
            >
              <span>Announcement</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
