import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditSymbolData = () => {
  const [userEmail, setUserEmail] = useState("");
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    userEmail: "",
    symbol: "",
    multiplier: 0,
    lines: "",
  });

  useEffect(() => {
    const getEmail = localStorage.getItem("email");
    setUserEmail(getEmail);
    fetchData(getEmail);
  }, []);

  const fetchData = async (email) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/get-symbol-detail/${email}/${id}`
      );
      console.log(res?.data?.data);
      const data = res?.data?.data;
      setFormData({
        symbol: data.symbol_name,
        multiplier: data.multiplier,
        lines: data.line_details,
      });
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error("Your request have failed !", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      formData.userEmail = userEmail;
      formData.id = id;
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/edit-symbol-details`,
        formData
      );
      console.log("formData", formData, res);
      if (res?.status === 200) {
        setFormData({
          id: "",
          symbol: "",
          multiplier: 0,
          lines: "",
          userEmail: "",
        });
        navigate("/dashboard");
      } else {
         toast.error("Please Try Again !", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
         toast.error("Please Try Again !", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      console.log(error);
      toast.error("Internal Server Error !", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div>
      <div>
        <form className="" onSubmit={handleSubmit}>
          <div className="">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Edit Info
            </h1>
            <div className="mt-4">
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Symbol
                </label>
                <input
                  type="text"
                  name="symbol"
                  className="form-control"
                  value={formData.symbol}
                  onChange={handleChange}
                  aria-describedby="emailHelp"
                  placeholder="Enter symbol"
                  required
                />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Multiplier
                </label>
                <input
                  type="number"
                  name="multiplier"
                  className="form-control"
                  value={formData.multiplier}
                  onChange={handleChange}
                  placeholder="Enter multiplier"
                  required
                />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Lines
                </label>
                <textarea
                  name="lines"
                  className="form-control"
                  value={formData.lines}
                  onChange={handleChange}
                  placeholder="Enter lines"
                  rows={5}
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn w-full btn-primary "
              style={{ background: "#0a3d89", width:"100%" }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditSymbolData;
