import './App.css';
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from './components/login';
import Main from './components/Main/main';
import { useEffect, useState } from 'react';
import { Dashboard } from './components/Dashboard/dashboard';
import AddSymbolData from './components/AddSymbolData/addSymbolData';
import EditSymbolData from './components/EditSymbolData/editSymbolData';
import Announcement from './components/Announcment/announcment';
function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const getStatus = localStorage.getItem("isUserValid")
    console.log(getStatus);
    if (!getStatus) {
      navigate("/login");
    }
  }, [])
  return (
    <div className="App">

      <Routes>
        <Route path="/" index element={<Main><Dashboard /></Main>} />
        <Route path="dashboard" element={<Main><Dashboard /></Main>} />
        <Route path="announcement" element={<Main><Announcement /></Main>} />
        <Route path="addsymboldetails" element={<Main><AddSymbolData /></Main>} />
        <Route path="editsymboldetails/:id" element={<Main><EditSymbolData /></Main>} />
        <Route path="login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
