import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const ShowSymbolData = ({setLinesLimit}) => {
    const [data, setData] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        try {
            const getEmail = localStorage.getItem("email");
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/get-symbol-data/${getEmail}`)
            console.log(res?.data?.data);
            setData(res?.data?.data)
            setLinesLimit(res?.data?.data?.length)
        } catch (error) {
            console.error(error);
        }
    }
    const handleDelete = async (id) => {
        try {
            const getEmail = localStorage.getItem("email");
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-symbol/${getEmail}/${id}`)
            if (res.status === 200) {
                fetchData()
                console.log(res);
            }
        } catch (error) {
            console.error("Error in delete symbol function :", error);
        }
    }
    return (
        <div className="">
            <br />
            {/* <h3>Deta</h3> */}
            <br />
            <div className="overflow-x-auto">
                <table className="table table-striped table-hover">
                    <thead>
                        <th className="buy-hd-tab" style={{ textAlign: "start" }}>
                            Symbol
                        </th>
                        <th className="buy-hd-tab">multiplier</th>
                        <th className="buy-hd-tab">lines</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {
                            data &&
                            data.length &&
                            data?.map((item, i) => {
                                return (
                                    <tr className="fw-bold" key={i}>
                                        <td className="buy-bd-tab" style={{ textAlign: "start" }}>
                                            {item?.symbol_name || "ETH-USDT"}
                                        </td>
                                        <td className="buy-bd-tab">
                                            {item.multiplier}
                                        </td>
                                        <td className="buy-bd-tab" style={{ width: "250px" }}>
                                            <div className="scrollable-td">
                                                <div className="scrollable-content">{item.line_details}</div>
                                            </div>
                                        </td>
                                        <td><span onClick={() => {
                                            handleDelete(item?.id)
                                        }}><i class="bi bi-trash3" style={{ color: "red", cursor: "pointer" }}></i></span>
                                            <span onClick={() => navigate(`/editsymboldetails/${item?.id}`)}><i class="bi bi-pencil-square" style={{ color: "green", marginLeft: "30px", cursor: "pointer" }}></i></span></td>
                                    </tr>
                                );
                            }) || ''}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ShowSymbolData