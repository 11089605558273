import React, { useEffect, useState } from "react";
import SideBar from "../SideBar/sideBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Main = ({ children }) => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getEmail = localStorage.getItem("email");
    setEmail(getEmail);
    const getStatus = localStorage.getItem("isUserValid");
    console.log(getStatus);
    if (getStatus) {
      if (JSON.parse(getStatus)) {
        setIsValid(true);
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, []);
  const handleLogout = async () => {
    try {
      if (email) {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/logout`,
          { email }
        );
        if (res?.status === 200) {
          localStorage.removeItem("email");
          localStorage.setItem("isUserValid", false);
          navigate("/login");
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        alert("Invalid User");
      }
      console.log(error);
    }
  };
  return (
    <div>
      {isValid && (
        <div style={{ display: "flex" }}>
          <div style={{ background: "#1c2430" }}>
            <SideBar />
          </div>
          <div style={{ width: "100vw" }}>
            <div className="sub-header">
              <div></div>
              <div>
                <h2 className="headerHeading">Forex Lines</h2>
              </div>
              <div className="dropdown">
                <button
                  className="userTab dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="/vector.png"
                    alt=""
                    width={"40px"}
                    height={"40px"}
                  />
                </button>
                <ul className="dropdown-menu">
                  <li>{email || "..."}</li>
                  <li className="logoutBtn" onClick={handleLogout}>
                    Logout
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ padding: "1.4rem" }}>{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
